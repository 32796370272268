const meta = {
  resource: 'dashboard',
  action: 'all',
}
export default [
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  //   meta,
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  //   meta,
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardScreen.vue'),
    meta,
  },
  {
    path: '/how-to',
    name: 'how-to',
    component: () => import('@/views/apps/how-to/HowToScreen.vue'),
    meta,
  },
  {
    path: '/announce',
    name: 'announce',
    component: () => import('@/views/apps/announce/AnnounceScreen.vue'),
    meta,
  },
  {
    path: '/announce/:id',
    name: 'announce-details',
    component: () => import('@/views/apps/announce/AnnounceDetailsScreen.vue'),
    meta,
  },
  {
    path: '/announce/management/:id',
    name: 'announce-management',
    component: () => import('@/views/apps/announce/AnnounceManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
