import Vue from 'vue'
const self = Vue

const _state = () => {
  return {
    form: {
      userId: '',
      select: '',
    },
    results: '',
    loading: false,
    reloadQuery: false,
    options: [],
    loadings: {
      // หน้าหลักตั้งแต่ โหลดเข้ามาจาก login
      main: false,
    },
  }
}

export default {
  namespaced: true,
  state: _state(),
  getters: {
    _partnerInfo(state) {
      return state.results?.userInfo
    },
    _isClient(state) {
      return state.results?.userInfo?.role_data_id == 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
    _isPartner(state) {
      const roleDataId = state.results?.userInfo?.role_data_id
      return roleDataId == '974f843e-8b0e-4221-8bc5-767276a9f98e'
    },
    _isAdmin(state) {
      const roleDataId = state.results?.userInfo?.role_data_id
      return roleDataId == '7fa970cc-aa32-49bc-9cf6-50f996bc6385'
    },
    _isPartnerOrAdmin(state) {
      const roleDataId = state.results?.userInfo?.role_data_id
      return roleDataId != 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
  },
  mutations: {
    CLEAR_DATA(state) {
      try {
        Object.assign(state, _state())
        state.loading = false
      } catch (error) {}
    },
    CHANGE_RELOAD_QUERY(state) {
      state.reloadQuery = !state.reloadQuery
    },
    async QUERY_RESULTS(state) {
      state.loadings.main = false

      let url = `api/admin/dashboard/index`
      if (state.loading == true) {
        url = `${url}?dateFilter=${state.form.select}`
      }

      if (state.form.userId) {
        if (url.split('?').length > 1) {
          url = `${url}&userId=${state.form.userId}`
        } else {
          url = `${url}?userId=${state.form.userId}`
        }
      }

      await self.api
        .get(url, false)
        .then(result => {
          state.results = result
          state.loading = true
        })
        .catch(err => {})
      state.reloadQuery = false
      state.loadings.main = true
    },
    async QUERY_DATE(state) {
      // console.log('s')
      let url = `api/dashboard/fetch-date`

      if (state.form.userId) {
        url = `${url}?userId=${state.form.userId}`
      }
      state.options = await self.api.get(url)
      state.form.select = state.options[0]
      state.loading = true
    },
  },
  actions: {
    async fetchData({ commit }) {
      await commit('QUERY_DATE')
      await commit('QUERY_RESULTS')
    },
  },
}
