import Vue from 'vue'
import router from '@/router'

const displayAlert = (data = '', title = 'พบข้อผิดพลาด', variant = 'danger') => {
  const vm = new Vue()
  const h = vm.$createElement

  vm.$bvToast.toast(h('div', {}, data), {
    title,
    variant,
    solid: true,
  })
}

const initialState = () => {
  return {
    imagePreview: null,
    loading: false,
    type: 'upload',
    wallpaperDatas: {
      allImage: [],
      mainImage: {
        title: '',
        details: '',
        file: null,
        zodiac: null,
        youtube_link: '',
        blog_link: '',
        wallpaper_tags: [],
        hint_modal_free_image: '',
        hide_in_store: false,
        defaultValuePositionList: [],
        positionList: [],
        id: '',
        optionsFont: {
          TH: {
            name: null,
            style: null,
            options: [],
          },
          EN: {
            name: null,
            style: null,
            options: [],
          },
        },
        title_translations: {
          th: '',
        },
        details_translations: {
          th: '',
        },
        hint_modal_free_image_translations: {
          th: '',
        },
      },
    },
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {
    RESET_WALLPAPER_STATE(state) {
      Object.assign(state, initialState())
    },
    UPDATE_ALL_IMAGE(state, data) {
      state.allImage = data
    },
    SET_DATA_EDIT_IMAGE(state, { data, navigate = true }) {
      // console.log('SET_DATA_EDIT_IMAGE', data)

      if (!data.json_data) {
        displayAlert('ไม่พบข้อมูลเก่าของรูปวอลเปเปอร์นี้')
      } else {
        const mainImage = JSON.parse(data.json_data)
        mainImage.file.previewUrl = data.image_preview
        mainImage.id = data.id ? data.id : ''
        mainImage.youtube_link = mainImage.youtube_link ?? ''
        mainImage.blog_link = mainImage.blog_link ?? ''
        mainImage.wallpaper_tags = data?.wallpaper_tags ?? []
        mainImage.hint_modal_free_image = mainImage?.hint_modal_free_image ?? ''
        mainImage.hide_in_store = data?.hide_in_store ?? false
        mainImage.title_translations = { th: mainImage.title, ...data?.title_translations }
        mainImage.details_translations = { th: mainImage.details, ...data?.details_translations }
        mainImage.hint_modal_free_image_translations = {
          th: mainImage.hint_modal_free_image,
          ...data?.hint_modal_free_image_translations,
        }

        const allImage = []

        data.topic_image_list_datas.forEach(val => {
          // console.log('val', val)
          const obj = {
            file: {
              localId: null,
              naturalHeight: mainImage.file.naturalHeight,
              naturalWidth: mainImage.file.naturalWidth,
              previewUrl: val.image_preview,
            },
            localId: null,
            id: val.id,
            options: {
              dayColor: val.name ?? null,
              fontColor: val.color_code_data_id,
              price: Number(val.price),
              title: val.title ?? '',
              detail: val.detail ?? '',
              watermarkColor: val.watermark_color ?? 'black',
              title_translations: {
                th: val.title,
                ...val?.title_translations,
              },
              details_translations: {
                th: val.detail,
                ...val?.details_translations,
              },
            },
            ai_generate_data: val?.ai_generate_data,
          }
          allImage.push(obj)
        })

        state.wallpaperDatas.allImage = allImage
        state.wallpaperDatas.mainImage = mainImage
        if (navigate) {
          router.push({ name: 'wallpaper-upload-wallpaper' })
        }
      }
    },
    UPDATE_MAIN_POSITION_LIST(state, data) {
      // console.log('UPDATE_MAIN_DEFAULT_POSITION_LIST', data)
      state.wallpaperDatas.mainImage.positionList = [...data]
    },
  },
  actions: {},
}
