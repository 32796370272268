export default [
  {
    path: '/withdraw-management',
    name: 'withdraw-management',
    component: () => import('@/views/apps/admins/withdraw-management/WithdrawManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
