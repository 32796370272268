export default [
  {
    path: '/tools/short-link',
    name: 'tools-short-link',
    component: () => import('@/views/apps/tools/ShortLinkScreen.vue'),
    meta: {
      action: 'manage',
      resource: 'Auth',
    },
  },
]
