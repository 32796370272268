export default [
  {
    path: '/notification-management',
    name: 'notificationData',
    component: () => import('@/views/apps/admins/NotificationManagement'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/notification-setting',
    name: 'notificationSetting',
    component: () => import('@/views/apps/admins/notification-setting/NotificationSetting.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
