export default [
  // {
  //   path: '/bank-info',
  //   name: 'bankinfo',
  //   component: () => import('@/views/apps/partners/banks'),
  //   meta: {
  //     resource: 'partner',
  //     action: 'all',
  //   },
  // },
  {
    path: '/partner/settings',
    name: 'partner-settings',
    component: () => import('@/views/apps/partners/settings/PartnerSettingsScreen.vue'),
    meta: {
      resource: 'partner-setting',
      action: 'all',
    },
  },
  {
    path: '/partner/withdraw',
    name: 'partner-withdraw',
    component: () => import('@/views/apps/partners/withdraw/PartnerWithdrawScreen.vue'),
    meta: {
      resource: 'partner',
      action: 'all',
    },
  },
  {
    path: '/partner/withdraw-transactions',
    name: 'partner-withdraw-transactions',
    component: () => import('@/views/apps/partners/withdraw-transactions/PartnerWithdrawTransactionsScreen.vue'),
    meta: {
      resource: 'partner',
      action: 'all',
    },
  },
]
