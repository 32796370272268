export default [
  {
    path: '/report/credit',
    name: 'report-credit',
    component: () => import('@/views/apps/admins/report/ReportCreditScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/report/user-login',
    name: 'report-user-login',
    component: () => import('@/views/apps/admins/report/ReportUserLoginScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/report/user-login/country-details/:code',
    name: 'report-user-login-country-details',
    component: () => import('@/views/apps/admins/report/ReportUserLoginCountryDetailsScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
