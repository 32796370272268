export default [
  {
    path: '/slide-management',
    name: 'slideManagement',
    component: () => import('@/views/apps/admins/SlideDataManagement'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
