export default [
  {
    path: '/user-management',
    name: 'userManagement',
    component: () => import('@/views/apps/admins/userManagement'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/user-management/:userId',
    name: 'userManagement-user-info',
    component: () => import('@/views/apps/admins/userManagement/UserManagementDetailsScreen.vue'), // old clickViewDashboard.vue
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
