<template>
  <b-card>
    <h4 class="mb-1 ">{{ title }}</h4>
    <b-overlay :show="loading">
      <vue-good-table
        mode="remote"
        :line-numbers="false"
        :columns="cColumns"
        :rows="rows"
        :search-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :total-rows="totalRecords"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.type === 'custom'">
            <slot :name="props.column.field" :row="props.row">
              <pre>{{ props.row }}</pre>
            </slot>
          </span>

          <span v-else-if="props.column.type === 'currency'">
            {{ gFormatNumberToCurrency(props.row[props.column.field] || 0) }}
          </span>

          <span v-else-if="props.column.field === 'actions'">
            <b-dropdown variant="link" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" />
              </template>

              <b-dropdown-item
                v-for="action in actions"
                :key="action.keyEmit"
                @click="$emit(action.keyEmit, props.row)"
              >
                <feather-icon :icon="action.iconName" class="mr-50" :class="action.classIcon" />
                <span>{{ action.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap px-50 pb-50">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                แสดง 1 ถึง
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50']"
                class="mx-1"
                @input="value => props.perPageChanged({ currentPerPage: value })"
              />
              <span class="text-nowrap"> จากทั้งหมด {{ props.total }} รายการ </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: { VueGoodTable },
  props: {
    actions: {
      type: Array,
      default: () => [], // {keyEmit: '',label: '',iconName: ''}
    },
    columns: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: '',
      rows: [],
      loading: false,
      pageLength: 10,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    cColumns() {
      const c = [...this.columns].map(el => ({
        filterable: true,
        filterOptions: {
          enabled: true,
          placeholder: '',
        },
        ...el,
      }))
      if (this.actions.length > 0) {
        c.push({
          field: 'actions',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'text-center',
          filterable: false,
        })
      }
      return c
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      //   console.log(params)
      this.updateParams({
        sort: [
          {
            type: params[0].type === 'none' ? 'asc' : params[0].type,
            field: params[0].field,
          },
        ],
        // sort: [
        //   {
        //     type: params[0].type,
        //     field: this.columns[0].field,
        //   },
        // ],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async reloading() {
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      if (!this.url) return
      const params = { ...this.serverParams, filter: this.searchTerm }

      try {
        this.loading = true
        const resp = await this.api.post(this.url, params)
        const { total, data } = resp
        this.totalRecords = total
        this.rows = [...data]
      } catch (error) {
        // console.log('e', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
