import Vue from 'vue'
import ability from '../libs/acl/ability'

const self = Vue

export default {
  namespaced: true,
  state: {
    userInfo: '',
  },
  getters: {
    // eslint-disable-next-line no-underscore-dangle
    _isClient(state) {
      return state.userInfo?.role === 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isPartner(state) {
      return state.userInfo?.role === '974f843e-8b0e-4221-8bc5-767276a9f98e'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isAdmin(state) {
      return state.userInfo?.role === '7fa970cc-aa32-49bc-9cf6-50f996bc6385'
    },
    // eslint-disable-next-line no-underscore-dangle
    _isPartnerOrAdmin(state) {
      const roleDataId = state.userInfo?.role_data_id
      return roleDataId !== 'c6e63c72-aeda-4caf-aadb-5dba9f1b91fc'
    },
    // eslint-disable-next-line no-underscore-dangle
    _userInfo(state) {
      return state.userInfo
    },
    // eslint-disable-next-line no-underscore-dangle
    _isCanManagementRedeem(state) {
      if (state.userInfo?.role_data_preview === 'admin') return true

      if (
        state.userInfo?.role_data_preview === 'partner' &&
        (state.userInfo?.amount_upload_per_day === 0 || state.userInfo?.amount_upload_per_day >= 5)
      ) {
        return true
      }
      return false
    },
    // eslint-disable-next-line no-underscore-dangle
    _userRole(state) {
      let role

      if (state.userInfo && typeof state.userInfo === 'object' && state.userInfo.role_data_preview) {
        role = state.userInfo.role_data_preview
      } else {
        try {
          const userDataInLocal = JSON.parse(localStorage.getItem('userData') || {})
          role = userDataInLocal?.role
        } catch (error) {
          role = ''
        }
      }

      return role
    },
  },
  mutations: {
    // async FETCH_USER_INFO(state) {
    //   const resp = await self.api.get('api/user-info', false)
    //   // console.log('FETCH_USER_INFO', resp)
    //   if (resp.role_data_preview === 'partner') {
    //     const userData = localStorage.getItem('userData')
    //     if (!userData) return

    //     const userDataJson = JSON.parse(userData)

    //     const newAbility = [
    //       { action: 'manage', subject: 'partner-dashboard' },
    //       { action: 'manage', subject: 'partner-setting' },
    //       { action: 'manage', subject: 'Auth' },
    //     ]

    //     if (resp.approve_status_partner === true) {
    //       newAbility.push({ action: 'manage', subject: 'partner' })
    //     }

    //     userDataJson.ability = newAbility

    //     localStorage.setItem('userData', JSON.stringify(userDataJson))
    //     ability.update(newAbility)
    //   }
    //   state.userInfo = resp
    // },
    SET_USER_INFO(state, payload) {
      const info = JSON.parse(JSON.stringify({ ...payload }))
      if (info.role_data_preview === 'partner') {
        const userData = localStorage.getItem('userData')
        if (!userData) return

        const userDataJson = JSON.parse(userData)

        const newAbility = [
          { action: 'manage', subject: 'dashboard' },
          { action: 'manage', subject: 'partner-setting' },
          { action: 'manage', subject: 'Auth' },
        ]

        if (info.approve_status_partner === true) {
          newAbility.push({ action: 'manage', subject: 'partner' })
          newAbility.push({ action: 'all', subject: 'wallpaper' })
        }

        if (info?.amount_upload_per_day === 0 || info?.amount_upload_per_day >= 5) {
          newAbility.push({ action: 'all', subject: 'redeem-management' })
        }

        userDataJson.ability = newAbility

        localStorage.setItem('userData', JSON.stringify(userDataJson))
        ability.update(newAbility)
      }
      localStorage.setItem('role_wall', info.role_data_preview)
      state.userInfo = info
    },
    UPDATE_LIMIT_UPLOAD_WALLPAPER(state) {
      state.userInfo.upload_limit_used = state.userInfo.upload_limit_used += 1
    },
  },
  actions: {
    updateUserInfoAuthStore({ commit }, payload) {
      // console.log('updateUserInfoStore', payload)
      commit('SET_USER_INFO', payload)
    },
    async getUserInfo({ commit }) {
      const resp = await self.api.get('api/user-info', false)
      commit('SET_USER_INFO', resp)
    },
  },
}
