export default [
  {
    path: '/credit-management',
    name: 'creditManagement',
    component: () => import('@/views/apps/admins/CreditManagement'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
