export default [
  {
    path: '/partners-management',
    name: 'partners-management',
    component: () => import('@/views/apps/admins/partners-management/PartnersManagementScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/partners-management/details/:id',
    name: 'partners-management-details',
    component: () => import('@/views/apps/admins/partners-management/PartnersManagementDetailsScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
