import dayjs from 'dayjs'
import Vue from 'vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import mediumZoom from 'medium-zoom'
import axios from 'axios'
import MD5 from 'crypto-js/md5'

Vue.mixin({
  methods: {
    /**
     * แยกข้อมูลออกจาก master data
     * @note เอาไว้แยกข้อมูลออกมาจากข้อมูลหลัก เวลาแก้แล้วจะไม่ไปกระทบกับตับ master data
     * @param {Object} src
     */
    coppyParam(src) {
      return JSON.parse(JSON.stringify(src))
    },

    cp(src) {
      return JSON.parse(JSON.stringify(src))
    },

    withCommas(number, decimal = true) {
      let _return = 0
      if (number) {
        let temp
        if (decimal) {
          temp = parseFloat(number, 10)
            .toFixed(2)
            .split('.')
          _return = `${parseFloat(temp[0], 10).toLocaleString()}.${temp[1]}`
        } else {
          temp = parseFloat(number, 10)
          _return = `${parseFloat(temp, 10).toLocaleString()}`
        }

        // _return = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        _return = number
      }

      if (_return) {
        return _return
      }

      return 0
    },
    withoutCommas(number) {
      number.replace(',', '')
    },
    formatNumberToCurrency(value) {
      const f = new Intl.NumberFormat('th-TH')
      return f.format(value)
    },
    gFormatDate(date, showTime = false) {
      let format = 'DD MMM YYYY'
      if (showTime) {
        format = 'DD MMM YYYY HH:mm'
      }
      return dayjs(date)
        .locale('th')
        .format(format)
    },

    gFormatNumberToCurrency(value) {
      const f = new Intl.NumberFormat('th-TH')
      return f.format(value)
    },

    // return true || false
    async gCheckConfirmV1(titleText = 'ยืนยัน', bodyText = 'โปรดกดยืนยันอีกครั้ง เพื่อดำเนินการต่อ') {
      const check = await this.$bvModal.msgBoxConfirm(bodyText, {
        title: titleText,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        cancelVariant: 'flat-secondary',
        okTitle: 'ยืนยัน',
        cancelTitle: 'ยกเลิก',
        bodyClass: 'py-2',
        // headerClass: 'modal-confirm-custom',
        // headerTextVariant: 'light',
        // headerBgVariant: 'primary',
      })

      return check
    },

    async gAlertV1(titleText = 'title', bodyText = 'body') {
      await this.$bvModal.msgBoxOk(bodyText, {
        title: titleText,
        centered: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        cancelVariant: 'flat-secondary',
        okTitle: 'ยืนยัน',
        bodyClass: 'py-2',
      })
    },

    async gCheckImageGenerateAI(file) {
      try {
        const formData = new FormData()
        formData.append('image', file)

        const hashStr = `private-ongphra-api-${file.name}`
        const hashValue = MD5(hashStr).toString()

        const resp = await axios.post('https://checkimg.jinda789.co.th:2096/get_ai_percent', formData, {
          headers: { hashValue },
        })
        if (resp.status === 200) {
          return { ...resp.data }
        }
      } catch (error) {
        // console.log('[ERROR] gCheckImageGenerateAI',error);
      }
      return null
    },

    gOpenPageLoading() {
      Vue.swal({
        html: '<span aria-hidden="true" class="spinner-border text-primary"></span>',
        showDenyButton: false,
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        background: 'transparent',
      })
    },

    gClosePageLoading() {
      Vue.swal.close()
    },

    gDisplayToast(title = '', text = '', variant = 'success', icon = 'BellIcon') {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },

    gZoomImage(ev) {
      // const zoom = mediumZoom({ background: '#191f32', margin: 48 })
      // zoom.attach(ev.target)
      // zoom.on('closed', () => zoom.detach(), { once: true })
      const url = ev.target.currentSrc
      this.$viewerApi({
        // eslint-disable-next-line import/no-dynamic-require, global-require
        images: [url],
        options: {
          navbar: false,
          title: false,
        },
      })
    },

    gFormatRolePreview(role) {
      if (role === 'partner') return 'creator'
      return role
    },

    // delay => millisecond
    async gMockDelay(delay = 1000) {
      return new Promise(r => setTimeout(r, delay))
    },
  },
})
