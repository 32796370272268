export default [
  {
    path: '/user-transaction-data',
    name: 'UserTransactionData',
    component: () => import('@/views/apps/admins/UserTransactionData'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
