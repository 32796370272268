export default [
  {
    path: '/app-mobile-config',
    name: 'app-mobile-config',
    component: () => import('@/views/apps/admins/app-mobile-config/AppMobileConfigScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
  {
    path: '/app-mobile-i18n-config',
    name: 'app-mobile-i18n-config',
    component: () => import('@/views/apps/admins/app-mobile-config/AppMobileI18nConfigScreen.vue'),
    meta: {
      resource: 'admin',
      action: 'all',
    },
  },
]
