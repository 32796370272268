<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <div class="app-version-display text-muted">
      v.2.0.0
    </div>

    <!-- <buy-now /> -->
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import BuyNow from '@/components/BuyNow.vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import { localize } from 'vee-validate'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  methods: {
    ...mapActions('AuthStore', ['getUserInfo']),
  },
  async created() {
    if (localStorage.getItem('accessToken')) {
      await this.getUserInfo()
    }
  },
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    BuyNow,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function(val) {
      // console.log('locale', val)
      localize(val)
    },
  },
  beforeCreate() {
    const { locale } = this.$i18n
    localize(locale)

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
html,
body {
  font-family: 'Kanit', sans-serif;
}

#app {
  font-family: 'Kanit', sans-serif;
}

.medium-zoom-overlay,
.medium-zoom-image--opened {
  z-index: 99999;
}

.th-address-label {
  width: 100%;
  // margin-bottom: 1rem;
  margin-bottom: 0;
  .th-address-container {
    input.th-address-input {
      font-size: 1rem;
      padding: 0.438rem 1rem;
    }
  }
}

.ct-cursor-zoom {
  cursor: zoom-in;
}

.custom-tiny-mce-image-preview-responsive {
  max-width: 100%;
  height: auto;
}

.custom-pre-text-details {
  white-space: pre-wrap;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.vue-form-wizard {
  .wizard-navigation {
    .wizard-tab-content {
      padding: 15px 20px 15px;
    }
  }
}

.app-version-display {
  position: fixed;
  top: 0;
  right: 0;
  font-size: 10px;
}
</style>
